import _ from 'lodash';
import { Action } from 'redux-actions';
import {
  AsyncActionPayload,
  createAsyncActions,
} from '../../../helpers/create-async-actions';
import { isVODChannel } from '../../../../utils/channel-helpers';
import {
  NormalizedArrayOfVideos,
  normalizeArrayOfVideos,
} from '../../../helpers/normalized';
import { getChannel } from '../../../../api/public/channel/channel';
import { getHydratedData } from '../../../hydrated-data/hydrated-data';
import { SortOrder } from '@wix/wix-vod-gql-api/dist/src/public/channel/types';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/common';
import { publicApiV3 } from '../../../../api/v3/public';
import { getPublicApi } from '../../../../api/public';
import { ActionCreator } from '../../../redux.types';
import { MEDIA_TYPES } from '@wix/wix-vod-constants/common';
import {
  SortOptions,
  SortDirections,
  SortOrders,
} from '@wix/wix-vod-constants/api-types';
import { getChannelById } from '../../../../selectors/channels';

type Options = {
  paging: {
    size: number;
    cursor: string;
  };
  query: string;
  tag: string;
  category: string;
};

type ListPublicParams = {
  id: string;
  options: Options;
};

export type ListPublicResponse = {
  paging: {
    size?: number;
    cursor: string;
  };
  sort: SortOptions;
  data: NormalizedArrayOfVideos;
};

export type ListPublicChannelVideosAction = Action<
  AsyncActionPayload<ListPublicResponse, ListPublicParams>
>;

const ACTIONS = createAsyncActions<ListPublicResponse, ListPublicParams>(
  'SERVER.CHANNEL.VIDEOS.LIST',
);
export const NAMES = ACTIONS.NAMES;
const { START, SUCCESS, FAIL } = ACTIONS;

export const defaultParams = {
  sort: {
    order: 'custom',
    direction: 'asc',
  },
  paging: {
    size: 1000,
  },
};

export const listPublicChannelVideosGql: ActionCreator = (
  channelId: string,
  opts?: Options,
) => {
  const options: Options = _.merge({}, defaultParams, opts);

  return async (dispatch, getState) => {
    const params: ListPublicParams = { id: channelId, options };
    const state = getState();

    dispatch(START(params));

    const { channelVideos: hydratedChannelVideos } = getHydratedData(state);

    try {
      const response = await (hydratedChannelVideos?.data ||
        publicApiV3.channel.getChannelVideos({
          channelId,
          videosCount: options.paging.size,
          cursor: options.paging.cursor,
          sort: SortOrder.CUSTOM_DESC,
          query: options.query,
          tag: options.tag,
          category: options.category,
        }));

      const data: ListPublicResponse = {
        paging: { cursor: response.cursor, size: options.paging.size },
        sort: {
          order: SortOrders.Custom,
          direction: SortDirections.Ascending,
        },
        data: normalizeArrayOfVideos(response.items),
      };

      dispatch(SUCCESS(params, data));
      return data;
    } catch (error) {
      dispatch(
        FAIL(params, error, {
          analytics: { type: 'error', name: 'shared.channel.videos.list' },
        }),
      );
    }
  };
};

export const listPublicChannelVideos: ActionCreator = (
  channelId: string,
  opts?: Options,
) => {
  const options: Options = _.merge({}, defaultParams, opts);

  return async (dispatch, getState) => {
    const state = getState();

    if (isGraphAPIEnabled()) {
      return dispatch(listPublicChannelVideosGql(channelId, options));
    }

    const params = { id: channelId, options };
    const {
      channel: hydratedChannel,
      channelVideos: hydratedChannelVideos,
      templateMetaSiteId,
    } = getHydratedData(state);

    dispatch(START(params));

    let response;
    const publicApi = getPublicApi();

    const channel = hydratedChannel
      ? (await getChannel(channelId, hydratedChannel)).data
      : getChannelById(state, channelId);

    try {
      if (channel) {
        response = isVODChannel(channel)
          ? await publicApi.channelVideos.listChannelVideos({
              ...options,
              channelId: channel.id,
              hydratedChannelVideos,
              templateMetaSiteId,
              mediaType: MEDIA_TYPES.SECURE_VIDEO,
            })
          : await publicApi.youtubeVideosService.listYoutubeChannelVideos(
              channel,
              options,
              hydratedChannelVideos,
            );
      } else {
        response = await publicApi.channelVideos.listChannelVideos({
          ...options,
          channelId,
          templateMetaSiteId,
          mediaType: MEDIA_TYPES.SECURE_VIDEO,
        });

        if (!response.data.length) {
          const channelResponse = await getChannel(channelId, hydratedChannel);

          if (channelResponse.data.externalId) {
            response =
              await publicApi.youtubeVideosService.listYoutubeChannelVideos(
                channelResponse.data,
                options,
                hydratedChannelVideos,
              );
          }
        }
      }

      const data: ListPublicResponse = {
        paging: response.paging,
        sort: response.sort,
        data: normalizeArrayOfVideos(response.data),
      };

      dispatch(SUCCESS(params, data));
      return data;
    } catch (error) {
      dispatch(
        FAIL(params, error, {
          analytics: { type: 'error', name: 'shared.channel.videos.list' },
        }),
      );
    }
  };
};
