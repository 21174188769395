import { VIEW_MODES } from '@wix/wix-vod-constants/common';
import { getAppLocale } from '../../services/locale/locale';
import {
  WidgetData,
  WidgetDataV2,
  WidgetDataV3V2Mapping,
} from '../../worker/helpers/getWidgetData';
import { InitialData } from '../../worker/helpers/create-store';
import { VideoResponse } from '@wix/wix-vod-api-site-based/dist/src/private/services/video/video.types';

const asHttpResponse = <T>(data: T): { data: T } | null =>
  data ? { data } : null;

// TODO: it seems we don't have these, should we remove?
interface UnknownProperties {
  __TRANSLATIONS__: unknown;
  __LOCALE__: unknown;
  windowSize: unknown;
  staticsVersion: unknown;
  __EDITOR_TYPE__: unknown;
}

export const parseHydratedData = (hydratedData: InitialData) => {
  const source = hydratedData as InitialData & UnknownProperties;
  const {
    currentPageId,
    siteOwnerId,
    fullSiteUrl,
    templateMetaSiteId,
    metaSiteId,
    viewMode,
  } = source;

  const translations = source.__TRANSLATIONS__;

  const currentSiteUser = source.__CURRENT_SITE_USER__;

  const locale =
    source.__LOCALE__ ||
    getAppLocale({
      siteLocale: source.siteLocale,
    });

  return {
    biToken: source.biToken,
    experiments: source.__EXPERIMENTS__,
    windowSize: source.windowSize,
    metaSiteId,
    locale,
    translations,
    siteUrl: source.__SITE_URL__,
    staticsVersion: source.staticsVersion,
    editorType: source.__EDITOR_TYPE__,
    instance: source.instance,
    instanceId: source.instanceId,
    viewMode,
    compId: source.compId,
    currentSiteUser: viewMode === VIEW_MODES.SITE ? currentSiteUser : null,
    deviceType: source.deviceType,
    templateMetaSiteId,
    currentPageId,
    siteOwnerId,
    fullSiteUrl,
    ...parseWidgetData(source.widgetData || {}),
  };
};

const parseWidgetData = (widgetData: WidgetData) => {
  if (widgetData.apiVersion === 'v3') {
    if (widgetData.isSingleVideo) {
      return {
        singleVideo: asHttpResponse(widgetData.__SINGLE_VIDEO__),
        channel: {},
      };
    }

    return {
      channel: {
        data: {
          ...widgetData.__CHANNEL__,
          deal_info: widgetData.__CHANNEL__.dealInfo,
        },
      },
      channelVideos: asHttpResponse(widgetData.__VIDEOS__),
    };
  }
  return parseOldWidgetData(widgetData);
};

const parseOldWidgetData = (
  widgetData: WidgetDataV2 | WidgetDataV3V2Mapping,
) => {
  const singleVideo = widgetData.isSingleVideo
    ? widgetData.__SINGLE_VIDEO__
    : undefined;

  const channelVideos = !widgetData.isSingleVideo
    ? widgetData.__VIDEOS__
    : undefined;
  const channel = widgetData.__CHANNEL__;

  return {
    channel: asHttpResponse(channel),
    channelVideos: asHttpResponse(channelVideos),
    singleVideo: asHttpResponse(singleVideo || {}),
    singleVideoId: singleVideo && (singleVideo as VideoResponse).item_id,
  };
};
