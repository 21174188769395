import { IWixWindow } from '@wix/yoshi-flow-editor';
import { PublicChannelItem, PublicVideoItem } from '@wix/wix-vod-api/public';
import { PublicChannelItem as PublicChannelItemV2 } from '@wix/wix-vod-api-site-based/dist/src/public/services/channel/channel';
import type { IHttpClient } from '@wix/http-client';
import { dashify } from '@wix/wix-vod-shared/common';
import { PRODUCTION_HOSTS } from '@wix/wix-vod-constants/env';
import { PublicChannelService } from '@wix/wix-vod-gql-api/public';
import { getPublicApi } from '../../api/public';
import { MEDIA_TYPES, URL_TYPES } from '@wix/wix-vod-constants/common';
import { YoutubeChannelVideosResponse } from '@wix/wix-vod-api-site-based/dist/src/public/services/youtube/youtube';
import { VideoListResponse } from '@wix/wix-vod-api-site-based/dist/src/public/services/channel-videos/channel-videos';
import { YoutubeVideo } from '@wix/wix-vod-api-site-based/dist/src/public/services/youtube/youtube.types';
import { VideoResponse } from '@wix/wix-vod-api-site-based/dist/src/private/services/video/video.types';

type ViewMode = IWixWindow['viewMode']; // can not import the proper type;

type WidgetDataVideos = { items: PublicVideoItem[] };
type WidgetDataVideosV2 = { items: (PublicVideoItem | YoutubeVideo)[] };

type ApiVersion = 'v2' | 'v3' | 'v3v2mapping';

interface BaseWidgetData {
  apiVersion: ApiVersion;
  __TRANSLATIONS__: Record<string, string>;
}

interface BaseWidgetDataV3 extends BaseWidgetData {
  apiVersion: 'v3';
}

interface SingleVideoWidgetDataV3 extends BaseWidgetDataV3 {
  isSingleVideo: true;
  __SINGLE_VIDEO__: PublicVideoItem;
  __CHANNEL__: {};
}

interface ChannelVideoWidgetDataV3 extends BaseWidgetDataV3 {
  isSingleVideo: false;
  __CHANNEL__: PublicChannelItem;
  __VIDEOS__: WidgetDataVideos;
}

export type WidgetDataV3 = SingleVideoWidgetDataV3 | ChannelVideoWidgetDataV3;

interface BaseWidgetDataV2<A extends ApiVersion = 'v2'> extends BaseWidgetData {
  apiVersion: A;
  __CHANNEL__: PublicChannelItem | PublicChannelItemV2;
}

interface SingleVideoWidgetDataV2<
  T extends VideoResponse = VideoResponse,
  A extends ApiVersion = 'v2',
> extends BaseWidgetDataV2<A> {
  isSingleVideo: true;
  __SINGLE_VIDEO__: VideoResponse | YoutubeVideo | undefined;
}

interface ChannelWidgetDataV2<
  T extends VideoResponse = VideoResponse,
  A extends ApiVersion = 'v2',
> extends BaseWidgetDataV2<A> {
  isSingleVideo: false;
  __VIDEOS__: VideoListResponse | YoutubeChannelVideosResponse;
}

export type WidgetDataV2 = SingleVideoWidgetDataV2 | ChannelWidgetDataV2;

interface V3V2MappingVideoResponse extends VideoResponse {
  content_url: string;
}

export type WidgetDataV3V2Mapping =
  | SingleVideoWidgetDataV2<V3V2MappingVideoResponse, 'v3v2mapping'>
  | ChannelWidgetDataV2<V3V2MappingVideoResponse, 'v3v2mapping'>;

interface GetWidgetDataQueryParams {
  channelId: string;
  videoId?: string;
  instance: string;
  locale?: string;
  videosCount: number;
  apiVersion: ApiVersion;
  metaSiteId?: string;
}

interface GetWidgetDataOptions {
  baseUrl: string;
  viewMode: ViewMode;
  httpClient: IHttpClient;
  translations: Record<string, string>;
  queryParams: GetWidgetDataQueryParams;
}

const mapOldYoutubeResponseToVideos = (
  youtubeResponse: YoutubeChannelVideosResponse,
): WidgetDataVideosV2 => {
  return {
    items: youtubeResponse.videoItems,
  };
};

const getWidgetDataV2 = async ({
  queryParams,
  translations,
}: GetWidgetDataOptions): Promise<WidgetDataV2> => {
  const { channelId, videoId, videosCount } = queryParams;
  const publicApi = getPublicApi();
  const channel = (await publicApi.oldPublicChannelService.getInfo(channelId))
    .data;

  const baseWidgetData: BaseWidgetDataV2 = {
    apiVersion: 'v2',
    __TRANSLATIONS__: translations,
    __CHANNEL__: channel,
  };

  if (videoId) {
    const singleVideo = (
      await publicApi.oldPublicChannelVideos.getChannelVideoById(
        channelId,
        videoId,
      )
    ).data;
    return {
      ...baseWidgetData,
      __SINGLE_VIDEO__: singleVideo,
      isSingleVideo: true,
    };
  }
  const videos = channel.external_id
    ? (
        await publicApi.oldYoutubeService.getChannelVideos({
          externalId: channel.external_id,
          urlType: channel.url_type as URL_TYPES.CHANNEL | URL_TYPES.PLAYLIST,
          itemsCount: videosCount,
        })
      ).data
    : (
        await publicApi.oldPublicChannelVideos.getList({
          channelId,
          paging: {
            size: videosCount,
          },
          mediaType: MEDIA_TYPES.SECURE_VIDEO,
        })
      ).data;
  return {
    ...baseWidgetData,
    __VIDEOS__: videos ?? { items: [] },
    isSingleVideo: false,
  };
};

const getWidgetDataV3V2Mapping = async (
  options: GetWidgetDataOptions,
): Promise<WidgetDataV3V2Mapping> => ({
  ...(await getWidgetDataV2(options)),
  apiVersion: 'v3v2mapping',
});

const getWidgetDataV3 = async ({
  queryParams,
  baseUrl,
  viewMode,
  translations: __TRANSLATIONS__,
}: GetWidgetDataOptions): Promise<WidgetDataV3> => {
  const { videosCount, instance } = queryParams;
  const videoId = dashify(queryParams.videoId);
  const channelId = dashify(queryParams.channelId);
  if (!channelId) {
    throw Error('can not get widget data, no channel id');
  }

  const channelService = new PublicChannelService(
    instance,
    viewMode === 'Site' ? baseUrl : PRODUCTION_HOSTS.manage,
  );
  const widgetData = await channelService.getWidgetData({
    channelId,
    videosCount: Number(videosCount),
    singleVideoId: dashify(videoId) || null,
  });
  const baseData: BaseWidgetDataV3 = {
    apiVersion: 'v3',
    __TRANSLATIONS__,
  };
  if (widgetData.isSingleVideo) {
    return {
      ...baseData,
      __SINGLE_VIDEO__: widgetData.singleVideo,
      __CHANNEL__: {},
      isSingleVideo: true,
    };
  }

  const { videos, channel } = widgetData;
  return {
    ...baseData,
    __VIDEOS__: videos,
    __CHANNEL__: channel,
    isSingleVideo: false,
  };
};

export type WidgetData = WidgetDataV3 | WidgetDataV2 | WidgetDataV3V2Mapping;

export const getWidgetData = async (
  options: GetWidgetDataOptions,
): Promise<WidgetData> => {
  const { apiVersion } = options.queryParams;
  switch (apiVersion) {
    case 'v2':
      return getWidgetDataV2(options);
    case 'v3':
      return getWidgetDataV3(options);
    case 'v3v2mapping':
      return getWidgetDataV3V2Mapping(options);
  }
};
